@ss-pro-reg: 'source_sans_proregular';
@ss-pro-light: 'source_sans_prolight';
@ss-pro-bold: 'source_sans_probold';

@blue: #1f91c8;
@green: #19ae19;
@dark-brown: #333;
@grey: #eef3f9;

@shadow: 0 9px 18px 0 rgba(36, 83, 145, 0.14);

@desktop: ~"screen and (min-width: 1220px)";
@medium: ~"screen and (max-width: 1219px)";
@mobile: ~"screen and (max-width: 767px)";