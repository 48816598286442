body {
	color: #000;
	font: 20px/28px @ss-pro-light;
	&.popup-opened {
		position: fixed;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
}
body > svg {
	left: -9999px;
	position: absolute;
	top: -9999px;
}
input,
button {
	-webkit-appearance: none;
}
a {
	color: @blue;
	transition: color 0.2s ease;
	text-decoration: none;
	&:hover {
		color: @green;
	}
}
b {
	font-family: @ss-pro-bold;
}
.first-screen {
	@media @desktop {
		min-height: 100vh;
		background: @grey;
	}
}
.container {
	max-width: 1280px;
	padding: 0 30px;
	box-sizing: border-box;
	margin: 0 auto;
	@media @medium {
		padding: 0 15px;
	}
}
.holder{
	&:after{
		display: block;
		clear: both;
		content: '';
	}
}
.flex-container {
	display: flex;
	justify-content: space-between;
	width:100%;
}
.blue {
	color: @blue;
}
.green {
	color: @green;
}
.green-btn {
	color: #fff;
	background-color: @green;
	text-transform: uppercase;
	text-decoration: none;
	text-align: center;
	font-family: @ss-pro-bold;
	display: block;
	box-shadow: 0 3px 10px 0 rgba(25, 174, 25, 0.35);
	transition: background 0.5s ease;
	box-sizing: border-box;
	&:hover {
		background-color: #2dcb2d;
		color: #FFF;
	}
	&:active {
		background-color: #138613;
	}
	&.lg {
		width: 384px;
		font-size: 28px;
		line-height: 32px;
		padding: 24px 10px;
		border-radius: 40px;
		margin: 0 auto;
		@media @mobile {
			width: 290px;
			padding: 21px 10px;
			font-size: 24px;
		}
	}
	&.md {
		width: 202px;
		font-size: 16px;
		line-height: 20px;
		padding: 15px 10px;
		border-radius: 25px;
	}
	&.sm {
		width: 162px;
		font-size: 14px;
		line-height: 18px;
		padding: 9px 10px 8px;
		border-radius: 17px;
	}
}
.sticky-wrapper {
	padding-bottom: 8px;

	@media @mobile {
		padding: 0;
	}
}
.header {
	padding: 40px 0 0;
	background-color: #fff;

	@media @mobile {
		padding-top: 11px;
	}

	.flex-container {
		align-items: center;

		@media @mobile {
			flex-direction: column;
		}
	}
	.green-btn {
		display: none;

		@media @mobile {
			display: block;
		}
	}
	.fixed & {
		padding: 11px 0 15px;
		box-shadow: 0 9px 18px 0 rgba(36, 83, 145, 0.14);
		position: fixed;
		top: -100px;
		left: 0;
		right: 0;
		z-index: 50;
		transition: top 0.3s ease;
		.green-btn {
			display: block;
		}
	}
	.fixed.nav-up & {
		top: -100px;
	}
	.fixed.nav-down & {
		top: 0;
	}
	.fixed.onTop & {
		top: -100px;
	}
}
.fixed {
	.flex-container {
		@media @mobile {
			display: block;
			
			.logo {
				float: left;
				margin: 12px 0 0;
			}
			.header-btn {
				float: right;
				width: 44px;
				height: 44px;

				.green-btn.sm {
					width: 44px;
					height: 44px;
					text-indent: -9999px;
					overflow: hidden;
					border-radius: 50%;
					box-shadow: none;
					position: relative;

					&:after {
						content:"";
						background: url("../images/phone-icon.png") no-repeat;
						width: 21px;
						height: 21px;
						position: absolute;
						top: 11px;
						left: 11px;
					}
				}
			}
		}
	}
}
.logo {
	background: url("../images/logo.svg") no-repeat;
	width: 214px;
	height: 24px;
	text-indent: -9999px;
	display: none;
	.fixed & {
		display: block;
		@media @mobile {
			margin: 0 0 10px;
		}
	}

	@media @mobile {
		display: block;
	}
}
.promo-block {
	background: @grey;
	width: 100%;
	padding: 0 0 10px;
	text-align: center;
	position: relative;
	overflow: hidden;
	@media @desktop {
		height: 100vh;
		padding-bottom: 100px;
		box-sizing: border-box;
		min-height: 950px;
	}
	&:before {
		content:'';
		width: 6000px;
		height: 6000px;
		border-radius: 50%;
		position: absolute;
		bottom: 40px;
		left: 50%;
		margin-left: -3000px;
		background: #fff;
		@media @desktop {
			bottom: 140px;
		}
	}
	.container {
		position: relative;
		z-index: 2;
		@media @desktop {
			height: 100%;
			display: flex;
			justify-content: center;
			flex-direction: column;
		}
	}
	.green-btn {
		@media @desktop {
			position: absolute;
			bottom:0;
			left: 50%;
			transform: translateX(-50%);
		}
	}
	h1 {
		font: 52px/56px @ss-pro-bold;
		color: @dark-brown;
		margin-bottom: 50px;
		.blue {
			display: block;
		}
		@media @medium {
			font: 46px/50px @ss-pro-bold;
		}
		@media @mobile {
			font: 32px/36px @ss-pro-bold;
		}
	}
}
.header-contacts {
	padding-left: 112px;
	text-align: left;

	@media @mobile {
		padding: 20px 0;
		text-align: center;
		display: none;
	}
}
.tel, .mail {
	font: 24px/28px @ss-pro-reg;
	text-decoration: none;
	display: inline-block;
	margin-right: 35px;
	vertical-align: top;
	@media @mobile {
		margin: 0;
	}
}
.tel {
	color: #000;
}
.mail {
	color: @blue;
}
.promo-banner {
	position: relative;
	justify-content: center;
	@media @desktop {
		margin-bottom: 60px;
	}
	@media @medium {
		display: block;
	}
	img {
		vertical-align: top;
		width: 100%;
		display: block;
	}
	.left-part {
		width: 38%;
		background: url('../images/left-banner.svg') 0 50% no-repeat;
		position: relative;
		padding-top: 20px;
		max-width: 460px;
		margin-top: 45px;
		@media @medium {
			background: none;
			width: 100%;
			padding: 30px 0;
			max-width: 100%;
			margin: 0;
		}
	}
	.right-part {
		width: 42%;
		@media @medium {
			width: 100%;
			margin: 0 0 20px;
			img {
				max-width: 400px;
				margin: 0 auto;
			}
		}
	}
	.logo-img {
		padding-top: 29px;
		max-width: 330px;
		margin: 0 auto 20px;
		@media @medium {
			padding: 0;
		}
		img {
			display: block;
		}
	}
}
.promo-text {
	font: 28px/32px @ss-pro-reg;
	position: absolute;
	width: 265px;
	top: 300px;
	left: 50%;
	margin-left: -132px;
	span {
		font-family: @ss-pro-bold;
	}
	@media @medium {
		position: static;
		width: auto;
		margin-left: 0;
	}
}
.content {
	h1 {
		font: 52px/56px @ss-pro-bold;
		color: @dark-brown;
		margin-bottom: 50px;
		.blue {
			display: block;
		}
		@media @medium {
			font: 46px/50px @ss-pro-bold;
		}
		@media @mobile {
			font: 32px/36px @ss-pro-bold;
		}
	}
	h2 {
		font: 48px/52px @ss-pro-bold;
		color: @dark-brown;
		margin-bottom: 30px;
		text-align: center;
		@media @medium {
			font: 40px/44px @ss-pro-bold;
		}
		@media @mobile {
			font: 30px/34px @ss-pro-bold;
		}
	}
	.grey-block {
		&:first-child {
			@media @desktop {
				padding-top: 0;
			}
		}
	}
}
.grey-block {
	background-color: @grey;
	padding: 80px 0 50px;
	@media @medium {
		padding: 50px 0;
	}
}
.card-wrap {
	flex-wrap: wrap;
	margin-bottom: 40px;
	.item {
		width: calc(~"25% - 15px");
		background-color: #fff;
		border-radius: 5px;
		position: relative;
		margin-bottom: 30px;
		font: 22px/26px @ss-pro-reg;
		box-shadow: @shadow;
		text-align: center;
		box-sizing: border-box;
		cursor: pointer;
		padding-bottom: 50px;
		.item-box {
			padding: 20px 30px 20px 30px;
			display: block;
			cursor: pointer;
			color: #333;
			.img-box {
				height: 180px;
				line-height: 180px;
				margin-bottom: 20px;
			}
			img {
				width: 212px;
				vertical-align: middle;
			}
		}
		.btn-bottom {
			text-transform: uppercase;
			color: #fff;
			font: 20px/24px @ss-pro-bold;
			display: block;
			background-color: @blue;
			padding: 13px 0;
			text-decoration: none;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			opacity: 0;
			transition: opacity 0.5s ease;
			border-bottom-left-radius: 5px;
			border-bottom-right-radius: 5px;
			box-sizing: border-box;
			height: 50px;
			@media @medium {
				opacity: 1;
			}
		}
		&:hover {
			.btn-bottom {
				opacity: 1;
			}
		}
		&:nth-child(4n) {
			.tooltip-box {
				left: auto;
				right: 9px;
			}
		}
		@media @medium {
			width: calc(~"50% - 15px");
			&:nth-child(2n) {
				.tooltip-box {
					left: auto;
					right: 9px;
				}
			}
		}
		@media @mobile {
			width: 100%;
			.tooltip-box {
				padding: 10px 15px;
				width: 265px;
				bottom: auto;
				top: 10px;
				left: 9px !important;
			}
		}
	}
}
.tooltip-box {
	font: 18px/28px @ss-pro-reg;
	color: @dark-brown;
	background-color: #fff;
	width: 504px;
	border: 4px solid @blue;
	border-radius: 5px;
	padding: 20px 27px;
	box-sizing: border-box;
	position: absolute;
	left: 9px;
	bottom: 20px;
	z-index: 2;
	text-align: left;
	visibility: hidden;
	opacity: 0;
	transition: opacity 0.5s ease;
	@media @medium {
		width: 420px;
		padding: 20px;
	}
}
.qtip {
    position: absolute;
    left: -28000px;
    top: -28000px;
    display: none;
    max-width: 440px;
    min-width: 100px;
	background-color: #fff;
	border: 4px solid @blue;
	border-radius: 5px;
	box-sizing: border-box;
}

.qtip .qtip-content {
    position: relative;
	padding: 20px 27px;
    overflow: hidden;
    text-align: left;
    word-wrap: break-word;
	font: 18px/28px @ss-pro-reg;
	color: @dark-brown;
	@media @mobile {
		font-size: 14px;
		line-height: 18px;
	}
}

.tooltip {
	position: absolute;
	top: 20px;
	left: 20px;
	.toopltip-icon {
		width: 30px;
		height: 30px;
		background-color: #e9eaeb;
		position: relative;
		text-indent: -9999px;
		display: block;
		border-radius: 50%;
		&:after {
			content: '?';
			color: #fff;
			text-indent: 0;
			display: block;
			position: absolute;
			top: 0;
			left: 9px;
			font: 26px/30px @ss-pro-bold;
		}
	}
	&:hover {
		.toopltip-icon {
			background-color: @blue;
		}
	}
}
.feedback-block {
	background-color: #fff;
	border-radius: 5px;
	box-shadow: @shadow;
	padding: 50px 110px 30px 45px;
	position: relative;
	font: 20px/28px @ss-pro-reg;
	margin-bottom: 50px;
	&:after {
		position: absolute;
		top: 35px;
		right: 44px;
		background-image: url("../images/sprite.png");
		width: 54px;
		height: 46px;
		content: '';
	}
	.left-side {
		width: 30%;
	}
	.ttl {
		display: block;
		font: 28px/32px @ss-pro-reg;
		margin-bottom: 20px;
	}
	.right-side {
		width: 65%;
	}
	.info-line {
		font: 18px/22px @ss-pro-reg;
		a {
			text-decoration: none;
		}
	}
	@media @mobile {
		padding: 30px;
		&:after {
			display: none;
		}
		.feedback-item {
			display: block;
		}
		.left-side, .right-side {
			width: 100%;
		}
		.ttl {
			margin-bottom: 5px;
		}
		.left-side {
			margin-bottom: 20px;
		}
	}
}
.owl-dots {
	text-align: center;
	margin-top: 100px;
	@media @mobile {
		margin-top: 50px;
	}
	.owl-dot {
		display: inline-block;
		width: 16px;
		height: 16px;
		border: 2px solid #abdff5;
		border-radius: 50%;
		margin: 0 5px;
		background-color: #fff;
		&.active {
			background-color: @blue;
			border-color: @blue;
		}
	}
}
.trust-block {
	.item {
		width: 30%;
		font: 24px/28px @ss-pro-reg;
		position: relative;
		padding-top: 30px;
		box-sizing: border-box;
		min-height: 130px;
		img {
			position: absolute;
			top: 0;
			left: 0;
		}
		&:first-child {
			padding-left: 110px;
			img {
				width: 95px;
			}
		}
		&:nth-child(2) {
			padding-left: 115px;
			img {
				width: 105px;
			}
		}
		&:nth-child(3) {
			padding-left: 145px;
			img {
				width: 129px;
			}
		}
	}
	@media @medium {
		display: block;
		text-align: center;
		.item {
			display: inline-block;
			vertical-align: top;
			text-align: left;
			width: 320px;
			margin: 0 20px 10px;
		}
	}
	@media @mobile {
		.item {
			display: block;
			margin: 0 0 30px;
			width: 100%;
			padding-left: 0 !important;
			padding-top: 150px;
			text-align: center;
			img {
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}
}
.step-block {
	padding: 100px 0;
	h2 {
		max-width: 601px;
		margin: 0 0 -50px auto;
		text-align: left;
	}
	@media @medium {
		padding: 50px 0;
		h2 {
			max-width: 100%;
			margin: 0 0 30px;
			text-align: center;
		}
	}
}
.step-list {
	counter-reset: counter;
	font: 20px/24px @ss-pro-reg;
	color: @dark-brown;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	@media @desktop {
		padding-bottom: 60px;
	}
}
.step-item {
	@media @medium {
		margin: 0 0 50px;
	}
	@media @desktop {
		width: 49%;

		&:nth-child(2n) {
			margin: 160px 0 -100px;
		}
	}
}
.picture-box {
	width: 280px;
	position: relative;
	margin-right: 20px;
	float: left;
	&:before {
		content: counter(counter);
		counter-increment: counter;
		width: 60px;
		height: 60px;
		background-color: @blue;
		display: block;
		border-radius: 50%;
		color: #fff;
		text-align: center;
		font: 32px/60px @ss-pro-bold;
		position: absolute;
		right: 0;
		bottom: -30px;
	}
	img {
		display: block;
	}
	@media @medium {
		width: 200px;
	}
	@media @mobile {
		float: none;
		margin: 0 auto 20px;
		&:before {
			width: 40px;
			height: 40px;
			font: 22px/40px @ss-pro-bold;
			left: 0;
			right: auto;
			bottom: 0;
		}
	}
}
.sub-ttl {
	display: block;
	font: 28px/32px @ss-pro-reg;
	margin-bottom: 20px;
	@media @mobile {
		font: 24px/28px @ss-pro-reg;
		margin-bottom: 10px;
	}
}
.info-box {
	overflow: hidden;

	@media @desktop {
		padding-top: 50px;
	}
}
.payment-eta {
	background: #fff;
	box-shadow: @shadow;
	padding: 40px;
	font-size: 20px;
	line-height: 28px;
	margin-bottom: 50px;
	border-radius: 5px;
	.img-wrap {
		width: 30%;
		float: left;
		margin: 45px 50px 0 0;
		img {
			display: block;
			width: 100%;
		}
		@media @medium {
			display: none;
		}
	}
	.text-wrap {
		overflow: hidden;
		.cols {
			margin: 0 0 35px;
		}
		.col {
			width:47%;

			strong {
				display: block;
				font-family: @ss-pro-reg;
				font-size: 28px;
				margin: 0 0 20px;
			}
		}
	}
	.border-box {
		border: 4px solid @blue;
		border-radius: 5px;
		padding: 30px;

		p {
			&:first-child {
				margin: 0 0 20px;
			}
			strong {
				font-family: @ss-pro-bold;
				color: @blue;
			}
		}
	}
	@media @mobile {
		padding: 20px;
		.text-wrap {
			.cols {
				display: block;
				.col {
					width: 100%;
					margin-bottom: 20px;
					strong {
						font: 24px/28px @ss-pro-reg;
						margin-bottom: 10px;
					}
				}
			}
		}
		.border-box {
			padding: 15px;
		}
	}
}
.sevice-wrap {
	padding-bottom: 70px;
}
.service-box {
	background-color: #fff;
	border-radius: 5px;
	box-shadow: @shadow;
	margin-bottom: 10px;
	.main-info {
		padding: 20px 30px;
		cursor: pointer;
		align-items: center;
		box-sizing: border-box;
		@media @mobile {
			display: block;
		}
	}
	.l-side {
		font: 24px/28px @ss-pro-reg;
		position: relative;
		padding: 0 10px 0 32px;
		box-sizing: border-box;
		width: calc(~"100% - 250px");
		&:before, &:after {
			width: 4px;
			height: 16px;
			background-color: @blue;
			border-radius: 2px;
			box-shadow: 0 0 10px 1px rgba(31, 145, 200, 0.35);
			position: absolute;
			content: '';
		}
		&:before {
			transform: rotate(-35deg);
			left: 0;
			top: 2px;
		}
		&:after {
			transform: rotate(35deg);
			left: 0;
			top: 13px;
		}
		@media @mobile {
			width: 100%;
			margin-bottom: 20px;
		}
	}
	.active {
		.l-side {
			&:before {
				transform: rotate(-55deg);
				top: 7px;
			}
			&:after {
				transform: rotate(55deg);
				top: 7px;
				left: 11px;
			}
		}
	}
	.r-side {
		font: 20px/24px @ss-pro-bold;
		border-left: 2px solid @green;
		padding: 7px 0 7px 25px;
		box-sizing: border-box;
		width: 250px;
	}
	.drop-info {
		padding: 0 30px 30px 60px;
		font-size: 20px;
		line-height: 28px;
		color: @dark-brown;
		display: none;
	}
}
.tab-menu {
	font: 24px/28px @ss-pro-reg;
	text-align: center;
	padding-bottom: 20px;
	li {
		display: inline-block;
		margin: 0 20px 10px;
		a {
			color: @blue;
			text-decoration: none;
			border-bottom: 1px dashed @blue;
			&.active {
				border-bottom-color: transparent;
				color: @dark-brown;
			}
		}
	}
}
.tab-content {
	display: none;
	margin-bottom: 50px;
	&.active {
		display: block;
	}
}
.tab-block {
	background-color: #fff;
	box-shadow: @shadow;
	border-radius: 5px;
	border: 4px solid @blue;
	padding: 30px;
	justify-content: flex-start;
	box-sizing: border-box;
	@media @mobile {
		padding: 20px 15px;
	}
}
.picture-side {
	width: 30%;
	margin-right: 7%;
	@media @medium {
		display: none;
	}
}
.info-block {
	font-size: 20px;
	line-height: 28px;
	width: 63%;
	padding-top: 20px;
	@media @medium {
		width: 100%;
	}
	@media @mobile {
		padding-top: 0;
		font-size: 18px;
		line-height: 22px;
	}
}
.form-wrap {
	margin-bottom: 35px;
}
.form-item-holder {
	position: relative;
	display: inline-block;
	vertical-align: top;
	margin: 0 20px 20px 0;
	@media @mobile {
		margin: 0 0 20px;
		display: block;
	}
	input[type="checkbox"] {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
	}
	input[type="checkbox"] + label {
		padding-left: 70px;
		cursor: pointer;
		display: inline-block;
		vertical-align: middle;
		line-height: 50px;
		min-height: 50px;
		&:before {
			width: 50px;
			height: 50px;
			box-sizing: border-box;
			background-color: #fff;
			position: absolute;
			left: 0;
			top: 0;
			border: 2px solid #abdff5;
			border-radius: 3px;
			box-shadow: 0 3px 7px 1px rgba(97, 123, 158, 0.14);
			content: '';
		}
		&:after {
			background-image: url("../images/checkbox-arrow.svg");
			width: 28px;
			height: 27px;
			position: absolute;
			top: 11px;
			left: 13px;
		}
		@media @mobile {
			line-height: 22px;
			min-height: 25px;
			padding-left: 35px;
			&:before {
				width: 25px;
				height: 25px;
			}
			&:after {
				width: 17px;
				height: 17px;
				background-size: cover;
				top: 4px;
				left: 5px;
			}
		}
	}
	input[type="checkbox"]:checked + label {
		&:before {
			background-color: @blue;
			border-color: @blue;
		}
		&:after {
			content: '';
		}
	}
}
.jq-selectbox {
	text-align: center;
	width: 100px;
	vertical-align: middle;
	margin-right: 20px;
	@media @mobile {
		width: 75px;
		margin-right: 10px;
		float: left;
		& + label {
			display: block;
			overflow: hidden;
			padding: 10px 0 0;
		}
	}
}
.jq-selectbox__select {
	width: 100%;
	position: relative;
	padding: 11px 30px 11px 15px;
	box-sizing: border-box;
	border: 2px solid #e3f4fc;
	border-radius: 3px;
	font: 20px/24px @ss-pro-light;
	cursor: pointer;
	box-shadow: 0 3px 7px 0 rgba(97, 123, 158, 0.14);
	@media @mobile {
		padding: 7px 20px 7px 10px;
	}
}
.jq-selectbox__dropdown {
	width: 100%;
	background-color: #fff;
	border: 2px solid #e3f4fc;
	border-radius: 3px;
	box-sizing: border-box;
	text-align: left;
	margin-top: -2px;
	li {
		cursor: pointer;
		padding: 5px 10px;
		&:hover {
			background-color: #e3f4fc;
		}
	}
}
.jq-selectbox__trigger-arrow {
	background: url("../images/sprite.png") -61px 0;
	width: 15px;
	height: 7px;
	position: absolute;
	top: 50%;
	margin-top: -3px;
	right: 18px;
	@media @mobile {
		right: 8px;
	}
}
.result-row {
	font: 28px/32px @ss-pro-reg;
	.result-item {
		display: inline-block;
		margin-right: 30px;
	}
	@media @mobile {
		font: 24px/28px @ss-pro-reg;
		.result-item {
			display: block;
			margin: 0 0 20px;
		}
	}
}
.order-block {
	padding-top: 80px;
	@media @medium {
		padding-top: 30px;
		.container {
			display: block;
		}
	}
}
.sale-box {
	order: 2;
	width: 386px;
	border: 1px dashed @blue;
	border-radius: 7px;
	padding: 30px;
	box-sizing: border-box;
	font: 20px/28px @ss-pro-light;
	position: relative;
	margin-bottom: 180px;
	margin-top: 10px;
	.ttl {
		display: block;
		font: 48px/52px @ss-pro-bold;
		margin-bottom: 15px;
	}
	p {
		margin-bottom: 10px;
		a {
			color: @blue;
			text-decoration: none;
		}
	}
	.partner-box {
		text-align: center;
		background-color: #ffeea6;
		border-radius: 3px;
		padding: 25px;
		margin-bottom: 20px;
		img {
			max-width: 100%;
			vertical-align: top;
		}
	}
	.sale-pict {
		margin-bottom: -155px;
		text-align: center;
		padding-top: 20px;
		img {
			width: 226px;
			height: 228px;
		}
	}
	@media @medium {
		width: 100%;
		text-align: center;
	}
}
.order-box {
	width: 656px;
	order: 1;
	h2 {
		text-align: left;
		margin-bottom: 15px;
	}
	@media @medium {
		width: 100%;
		h2 {
			text-align: center;
		}
	}
}
.form-block {
	padding-top: 45px;
	margin-bottom: 50px;
	.top-row {
		box-shadow: 0 3px 7px 1px rgba(97, 123, 158, 0.14);
		margin-bottom: 20px;
		input {
			float: left;
			background-color: #fff;
			font: 18px/21px @ss-pro-reg;
			padding: 14px 15px;
			margin: 0;
			border: 2px solid #abdff5;
			width: 50%;
			box-sizing: border-box;
			outline: none;
			&:focus {
				border-color: @blue;
				@media @mobile {
					position: relative;
				}
			}
			@media @mobile {
				float: none;
				width:100%;
				margin-bottom: -2px;
				border-radius: 0;
			}
		}
		input[type="email"] {
			border-radius: 3px 0 0 3px;
			@media @mobile {
				border-radius: 0;
			}
		}
		input[type="tel"] {
			border-radius: 0 3px 3px 0;
			border-left: 0;
			@media @mobile {
				border-left: 2px solid #abdff5;
				border-radius: 0;
			}
			&:focus {
				border-left: 2px solid @blue;
				margin-left: -2px;
				width: calc(~"50% + 2px");
				@media @mobile {
					width:100%;
					margin-left: 0;
				}
			}
		}
	}
	.fields-box {
		box-shadow: 0 3px 7px 1px rgba(97, 123, 158, 0.14);
		margin-bottom: 20px;
		input[type="url"], textarea.url, textarea.description {
			width: 100%;
			box-sizing: border-box;
			background-color: #fff;
			padding: 14px 15px;
			margin: 0;
			border: 2px solid #abdff5;
			font: 18px/28px @ss-pro-reg;
			outline: none;
			float: left;
			resize: none;
			overflow: auto;
			&:focus {
				border-color: @blue;
			}
		}
		input[type="url"],
		textarea.url {
			border-radius: 3px 3px 0 0;
			height: 60px;
		}
		textarea.description {
			border-radius: 0 0 3px 3px;
			border-top: 0;
			resize: none;
			height: 200px;
			&:focus {
				border-top: 2px solid @blue;
				margin-top: -2px;
				height: 202px;
			}
		}
	}
	.bottom-row {
		align-items: center;
		.jq-file {
			overflow: hidden;
			z-index: 10;
			cursor: pointer;
			display: block;
			padding: 9px 0;
			.jq-file__name {
				font: 18px/22px @ss-pro-reg;
				color: @blue;
				padding-left: 40px;
				cursor: pointer;
			}
			.jq-file__browse {
				cursor: pointer;
				position: absolute;
				left: 0;
				top: 5px;
				width: 30px;
				height: 30px;
				border-radius: 50%;
				background-color: @blue;
				text-indent: -9999px;
				box-shadow: 0 3px 10px 0 rgba(31, 145, 200, 0.35);
				&:before {
					width: 4px;
					height: 18px;
					border-radius: 1px;
					background-color: #fff;
					position: absolute;
					top: 6px;
					left: 13px;
					content: '';
					cursor: pointer;
				}
				&:after {
					width: 18px;
					height: 4px;
					border-radius: 1px;
					background-color: #fff;
					position: absolute;
					top: 13px;
					left: 6px;
					content: '';
					cursor: pointer;
				}
			}
		}
		@media @mobile {
			display: block;
			.jq-file {
				margin-bottom: 20px;
			}
		}
	}
}
.note {
	font: 14px/18px @ss-pro-light;
}
.footer {
	color: #999;
	font: 14px/18px @ss-pro-light;
	padding: 15px 0 50px;
}
.popup-box {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	box-sizing: border-box;
	z-index:1003;
	height: 100%;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s ease;
	display: flex;
	overflow: auto;
	padding: 30px;
	box-sizing: border-box;
	&.visible {
		opacity: 1;
		visibility: visible;
	}
	h2,
	p {
		text-align: center;
	}
	.order-box {
		margin: auto;
		flex: 0 0 auto;
	}
	.close-btn {
		width:50px;
		height:50px;
		position: absolute;
		top:50px;
		right:50px;
		text-indent: -9999px;

		@media @medium {
			width:25px;
			height:25px;
			top: 15px;
			right: 15px;
		}

		&:before,
		&:after {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			width: 50px;
			height: 2px;
			background: @green;
			@media @medium {
				width:25px;
			}
		}
		&:before {
			transform: rotate(45deg)
		}
		&:after {
			transform: rotate(-45deg)
		}
	}
	h2 {
		&:extend(.content h2);
	}
}
.fader {
	background: rgba(255,255,255,0.8);
	position: fixed;
	top:0;
	left:0;
	width:100%;
	height:100%;
	z-index:1002;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s ease;
	&.visible {
		opacity: 1;
		visibility: visible;
	}
}
.page.blurred {
	filter: blur(10px);
}