@font-face {
	font-family: 'source_sans_proregular';
	src: url('../fonts/sourcesanspro.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'source_sans_prolight';
	src: url('../fonts/sourcesansprolight.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'source_sans_probold';
	src: url('../fonts/sourcesansprobold.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}